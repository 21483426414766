<template>
  <div class="turntable-box">
    <!-- 页面顶部内容 -->
    <div class="turntable-top u-flex-col u-row-center u-col-center">
      <div class="double-11">
        <img src="./imgs/11.png" alt="" />
      </div>
      <div class="turntable-top-tit">
        <img src="./imgs/title.png" alt="" />
      </div>
      <div class="turntable-top-desc">来融树客赢免息贷款，助你清空购物车</div>
      <div class="turntable-top-time">活动时间：10月20日至11月19日</div>
      <div class="rule-btn" @click="$router.push('/activity/turntable/rule')">
        活动规则
      </div>
    </div>
    <!-- 转盘 -->
    <div class="u-flex u-row-center lucky-box">
      <!-- 转盘插件 -->
      <LuckyWheel
        ref="myLucky"
        width="300px"
        height="300px"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        @end="endCallback"
      />
      <!-- 参与人数 -->
      <div class="turntable-num u-flex u-row-center u-col-center">
        <div class="turntable-small-num u-flex-col u-row-center u-col-center">
          <div>{{ datas.participationNumber }}</div>
          <div>人数参与</div>
        </div>
      </div>
    </div>
    <!-- 按钮模块和通知 -->
    <div class="turntable-btn u-flex u-row-center">
      <!-- 通知 -->
      <van-notice-bar :scrollable="false" class="notice-box">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item
            v-for="(item, index) in datas.shufflingVOList"
            :key="index"
          >
            <div class="u-flex u-row-between u-col-center u-line-1">
              <div class="u-flex-1 u-line-1 u-flex u-row-center u-col-center">
                {{ item.subTitle }}, {{ item.title }}
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
      <!-- 转盘按钮 -->
      <div class="turntable-b">
        <van-button
          class="t-b"
          type="primary"
          size="large"
          :disabled="datas.drawNumber == 0"
          @click="startCallback"
        >
          {{ btnText }}
        </van-button>
      </div>
    </div>
    <!-- 用户抽奖信息 -->
    <div class="user-info u-flex u-row-around u-col-center">
      <!-- 抽奖次数 -->
      <div class="u-text-center lottery-number">
        剩余机会: {{ datas.drawNumber }}次
      </div>
      <!-- 我的奖品 -->
      <div class="my-prize" @click="$router.push('/activity/turntable/prize')">
        <span>我的奖品</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <!-- 任务 -->
    <div class="task-box">
      <!-- 标题 -->
      <div class="task-title u-flex u-row-center u-col-center">
        {{ supplementText }}
      </div>
      <!-- 任务1 -->
      <div class="task-item u-flex u-row-between u-col-center">
        <img class="task-icon" src="./imgs/task_1.png" alt="" />
        <div class="u-flex-1">
          <div class="task-name">申请一个为您推荐的产品</div>
          <div class="task-desc">
            每申请1个产品将增加1次抽奖机会({{ datas.productNumber }}/3）
          </div>
        </div>
        <van-button
          type="primary"
          :disabled="datas.productNumber == 3"
          class="task-btn"
          @click="go2AppNativePage()"
        >
          {{ datas.productNumber == 3 ? "已完成" : "去完成" }}
        </van-button>
      </div>
      <!-- 任务2 -->
      <div
        v-if="datas.isHideLoanTip === 0"
        class="task-item u-flex u-row-between u-col-center"
      >
        <img class="task-icon" src="./imgs/task_2.png" alt="" />
        <div class="u-flex-1">
          <div class="task-name">完成贷款申请</div>
          <div class="task-desc">完成贷款申请，可额外获得2次抽奖机会</div>
        </div>
        <van-button type="primary" class="task-btn" @click="go2AppNativePage()">
          去完成
        </van-button>
      </div>
      <!-- 任务3 -->
      <div class="task-item u-flex u-row-between u-col-center">
        <img class="task-icon" src="./imgs/task_3.png" alt="" />
        <div class="u-flex-1">
          <div class="task-name">邀请好友完成注册</div>
          <div class="task-desc">每邀请一个好友注册将增加1次抽奖机会</div>
        </div>
        <van-button
          type="primary"
          class="task-btn"
          v-clipboard:copy="registerLink"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          去完成
        </van-button>
      </div>
    </div>
    <!-- 抽奖弹窗 -->
    <van-overlay :show="show" @click="show = false">
      <div class="draw-box u-flex u-row-center u-col-center">
        <div class="draw-item u-flex u-row-center u-col-center">
          <div
            class="draw-elem u-flex-col u-row-between u-col-center"
            @click.stop
          >
            <!-- 抽奖标题 -->
            <div class="draw-title u-flex u-row-center u-col-center">
              {{ drawText.title }}
            </div>
            <!-- 抽奖内容 -->
            <div class="draw-cont">
              <div class="draw-cont-tit">{{ drawText.content }}</div>
              <div class="draw-cont-desc">{{ drawText.desc }}</div>
            </div>
            <!-- 抽奖按钮 -->
            <div class="draw-btn" @click="handleDraw">
              <div class="u-flex u-row-center u-col-center">
                {{ drawText.btnText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 邀请弹窗 -->
    <van-overlay :show="inviteShow" @click="inviteShow = false">
      <div class="invite-box u-flex u-row-center u-col-center">
        <div class="invite-item u-flex u-row-center u-col-center">
          <div
            class="invite-elem u-flex-col u-row-between u-col-center"
            @click.stop
          >
            <!-- 邀请标题 -->
            <div class="invite-title u-flex u-row-center u-col-center">
              邀请链接已复制
            </div>
            <!-- 邀请内容 -->
            <div class="invite-cont">快去微信/QQ或其他方式发送给好友吧</div>
            <!-- 邀请按钮 -->
            <div
              class="invite-btn u-flex u-row-center u-col-center"
              @click="inviteShow = false"
            >
              好的
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import VueLuckyCanvas from "@lucky-canvas/vue";
Vue.use(VueLuckyCanvas);

import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import { luckIndex, luckDraw } from "@/api/activity/turntable";

export default {
  name: "Turntable",
  data() {
    return {
      // 转盘背景样式
      blocks: [
        {
          padding: "20px",
          imgs: [
            {
              src: require("./imgs/turn_bg.png"),
              width: "300px",
              height: "300px",
            },
          ],
        },
      ],
      // 转盘参数样式
      prizes: [],
      // 转盘按钮样式
      buttons: [
        {
          imgs: [
            {
              src: require("./imgs/zhizhen.png"),
              width: "92px",
              height: "113px",
              top: "-64px",
            },
          ],
        },
      ],
      // 页面展示数据
      datas: {
        participationNumber: 0,
        shufflingVOList: [],
        rewardVOList: [{ serialVersionUID: 0, id: 0, reward: "str" }],
        drawNumber: 0,
        productNumber: 0,
        inviteStatus: 0,
        supplementTimes: 0,
        isHideLoanTip: 1,
      },
      // 中奖的索引
      drawIndex: null,
      // 抽奖弹窗状态
      show: false,
      // 中奖类型 1:中奖，2:未中奖，3:抽奖次数不足（可补充），4:抽奖次数不足（明天再来）
      popupType: null,
      // 邀请链接
      registerLink: null,
      // 邀请弹窗状态
      inviteShow: false,
    };
  },
  computed: {
    // 抽奖文案
    drawText() {
      let title = "";
      let content = "";
      let desc = "";
      let btnText = "";
      if (this.popupType == 1) {
        title = "恭喜你获得";
        content = this.prizes[this.drawIndex].fonts[0].text;
        desc = "— 已存入您的账户中 —";
        btnText = "继续抽奖";
      } else if (this.popupType == 2) {
        title = "很遗憾未中奖";
        content = "谢谢您的参与";
        desc = "再接再厉哦";
        btnText = "继续抽奖";
      } else if (this.popupType == 3) {
        title = "抱歉，机会已用完";
        content = "抽奖次数不足";
        desc = `您还可以补充${this.datas.supplementTimes}次抽奖机会`;
        btnText = "去补充";
      } else if (this.popupType == 4) {
        title = "抱歉，机会已用完";
        content = "抽奖次数不足";
        desc = "明天再来看看吧";
        btnText = "好的";
      }
      return {
        title,
        content,
        desc,
        btnText,
      };
    },
    // 抽奖按钮文案
    btnText() {
      return this.datas.drawNumber == 0 ? "抽奖次数不足，快去领取" : "转动转盘";
    },
    // 任务标题文案
    supplementText() {
      return this.datas.supplementTimes > 0
        ? `今日还可额外转动${this.datas.supplementTimes}次转盘`
        : "额外机会已用尽，请明日再来哦";
    },
  },
  created() {
    this.luckIndex();
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      if (!this.datas.drawNumber) {
        if (this.datas.supplementTimes) {
          this.popupType = 3;
        } else {
          this.popupType = 4;
        }
        this.show = true;
        return false;
      }
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play();
      luckDraw().then((res) => {
        if (res.code === 200) {
          setTimeout(() => {
            this.drawIndex = res.data.index;
            this.popupType = res.data.popupType;
            this.$refs.myLucky.stop(this.drawIndex);
          }, 3000);
        }
      });
    },
    // 抽奖结束会触发end回调
    endCallback() {
      this.show = true;
      this.luckIndex();
    },
    // 页面展示数据接口
    luckIndex() {
      luckIndex().then((res) => {
        if (res.code === 200) {
          this.datas = res.data;
          this.prizes = this.datas.rewardVOList.map((item, index) => {
            return {
              background: index % 2 ? "#fff" : "#FCEFCB",
              fonts: [
                {
                  text: item.reward,
                  fontSize: "10px",
                  fontColor: "#AF6337",
                  top: "12px",
                },
              ],
              imgs: [
                {
                  src: require(`./imgs/turn_icon_${item.imgType}.png`),
                  width: "38px",
                  height: "38px",
                  top: "32px",
                },
              ],
            };
          });
          // 邀请链接
          this.registerLink = this.datas.inviteUrl;
        }
      });
    },
    // 弹窗按钮点击事件
    handleDraw() {
      this.show = false;
      if (this.popupType === 1 || this.popupType === 2) {
        this.startCallback();
      }
    },
    // 复制成功
    onCopy() {
      this.inviteShow = true;
    },
    // 复制失败
    onError() {
      this.$toast.fail("复制失败");
    },
  },
};
</script>

<style lang="scss" scoped>
.turntable-box {
  line-height: 1.5;
  background: #ea3d2f url("./imgs/bg.png") no-repeat center top;
  background-size: 100%;
  padding-bottom: 0.6rem;
}
.turntable-top {
  padding-top: 20px;
  color: #fff;
  position: relative;

  .double-11 {
    width: 2.56rem;
    height: 0.38rem;
    margin-bottom: 0.2rem;

    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .turntable-top-tit {
    width: 5.44rem;
    height: 1.1rem;
    margin-bottom: 0.2rem;

    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .turntable-top-desc {
    font-size: 0.28rem;
    text-align: center;
    margin-bottom: 0.16rem;
    background: url("./imgs/desc_bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 0 0.3rem;
    font-size: 0.32rem;
    font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
    font-weight: 400;
    color: #ea3d2f;
    line-height: 28px;
    letter-spacing: -0.27px;
  }

  .turntable-top-time {
    font-size: 0.24rem;
    font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffefc5;
    line-height: 0.34rem;
    letter-spacing: -0.2px;
  }

  .rule-btn {
    position: absolute;
    top: 0.4rem;
    right: 0;
    width: 1.36rem;
    height: 0.48rem;
    background: rgba(178, 25, 15, 0.5);
    border-radius: 2rem 0px 0px 2rem;
    text-align: center;
    font-size: 0.24rem;
    font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
    font-weight: 400;
    color: #ffefc5;
    line-height: 0.48rem;
    letter-spacing: -0.2px;
  }
}

.notice-box {
  position: absolute;
  top: 0.4rem;
  width: 5.1rem;
  height: 0.4rem;
  z-index: 1;
  border-radius: 0.2rem;
  background-color: rgba(203, 50, 53, 0.8);
  color: #fff1bd;
  padding: 0 0.2rem;
  font-size: 0.22rem;
  .notice-swipe {
    width: 100%;
    height: 0.4rem;
    line-height: 0.4rem;
  }

  .notice-time {
    margin-left: 0.2rem;
  }

  /deep/ {
    .van-notice-bar__content.van-ellipsis {
      width: 100%;
    }
  }
}

.lucky-box {
  margin-top: 0.2rem;
  position: relative;

  .turntable-num {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .turntable-small-num {
      width: 70px;
      height: 70px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #fbec9d;
      line-height: 17px;
    }
  }
}

.turntable-btn {
  padding-top: 1rem;
  background: url("./imgs/btn_bg.png") no-repeat center top;
  background-size: 5.44rem 2.38rem;
  margin-top: -0.2rem;
  position: relative;

  .turntable-b {
    display: flex;
    background: url("./imgs/b_bg.png") no-repeat;
    background-size: 100% 100%;
    border: none;
    width: 5.04rem;
    height: 1.42rem;

    .t-b,
    .t-b::before {
      height: 1.3rem;
      background: transparent;
      border: none;
      font-size: 0.36rem;
      font-weight: bold;
      color: #c3391c;
    }
  }
}

.user-info {
  font-size: 0.28rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #fbec9d;
  line-height: 0.4rem;
  padding: 0 1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}

.task-box {
  width: 6.9rem;
  margin: 0 auto;
  background: #fefdf9;
  border-radius: 10px;
  padding: 0 0.2rem 0.32rem;

  .task-title {
    background: url("./imgs/task_tit.png") no-repeat center;
    background-size: 100% 100%;
    width: 4.6rem;
    height: 0.74rem;
    margin: 0 auto;
    margin-bottom: 0.3rem;
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #b2693e;
  }

  .task-item {
    padding: 0.18rem 0.12rem;
    background: #ffeee6;
    border: 1px solid #ffdcca;
    border-radius: 4px;
    margin-bottom: 0.2rem;

    .task-icon {
      display: block;
      width: 0.84rem;
      height: 0.84rem;
      margin-right: 0.12rem;
    }

    .task-name {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #af6337;
      line-height: 0.4rem;
    }

    .task-desc {
      font-size: 0.22rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #88684f;
      line-height: 0.32rem;
    }

    .task-btn {
      width: 1.32rem;
      height: 0.6rem;
      background: #eb3f35;
      border-radius: 0.3rem;
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 0.4rem;
      margin-left: 0.2rem;
      border: 1px solid #eb3f35;
      padding: 0 0.2rem;
    }
  }
}

// 抽奖弹窗样式
.draw-box {
  width: 100%;
  height: 100%;

  .draw-item {
    width: 7.5rem;
    height: 9.34rem;
    background: url("./imgs/draw_bg.png") no-repeat;
    background-size: 100%;
    margin-top: -2rem;
  }

  .draw-elem {
    width: 6rem;
    height: 6.4rem;
    margin-top: 0.4rem;
  }

  .draw-title {
    font-size: 0.48rem;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #fef8dd;
    height: 1.4rem;
  }

  .draw-cont {
    margin-bottom: 0.6rem;
  }

  .draw-cont-tit {
    font-size: 0.4rem;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #d0442d;
    line-height: 0.56rem;
  }

  .draw-cont-desc {
    font-size: 0.24rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #cfa167;
    line-height: 0.34rem;
    margin-top: 0.2rem;
  }

  .draw-btn {
    position: relative;
    margin-bottom: 0.8rem;

    > div {
      width: 4.2rem;
      height: 1.08rem;
      background: linear-gradient(180deg, #fffef5, #ffd663);
      border: 0.06rem solid #fefad7;
      border-radius: 0.6rem;
      font-size: 0.4rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #d0442d;
      line-height: 0.56rem;
      position: relative;
      z-index: 1;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      top: 0.14rem;
      width: 100%;
      height: 1.08rem;
      background: #f2ad3d;
      border-radius: 27px;
    }
  }
}

.invite-box {
  width: 100%;
  height: 100%;

  .invite-item {
    width: 7.5rem;
    height: 9.34rem;
    background: url("./imgs/invite_bg.png") no-repeat;
    background-size: 100%;
  }

  .invite-elem {
    width: 6rem;
    height: 4.34rem;
    margin-top: -0.4rem;
  }

  .invite-title {
    font-size: 0.48rem;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #855126;
    line-height: 0.66rem;
    margin-top: 0.72rem;
  }

  .invite-cont {
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #cfa167;
    line-height: 0.4rem;
  }

  .invite-btn {
    width: 4.8rem;
    height: 1.08rem;
    background: #eb3f35;
    border-radius: 0.54rem;
    font-size: 0.4rem;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.56rem;
    margin-bottom: 0.72rem;
  }
}
</style>
